import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The IPv4Mask scalar type is an IPv4 netmask represented as UTF-8 character sequences.
Accepted formats are:`}</p>
    <ul>
      <li parentName="ul">{`Short notation (number of leading zeros in binary). i.e: `}<inlineCode parentName="li">{`32`}</inlineCode>{`, `}<inlineCode parentName="li">{`24`}</inlineCode>{`, `}<inlineCode parentName="li">{`8`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Long notation (octets separated by dots). i.e: `}<inlineCode parentName="li">{`255.255.255.255`}</inlineCode>{`, `}<inlineCode parentName="li">{`255.255.255.0`}</inlineCode>{`, `}<inlineCode parentName="li">{`255.0.0.0`}</inlineCode>{`.`}</li>
    </ul>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> IPv4Mask</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      